.story-button2-wrapper {
  flex-grow: 1;

  display: flex;
}

.story-button2-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.story-button2 {
  word-break: break-word;
  padding: 1rem;
  flex-grow: 1;
  font-size: 1rem;
}

.story-winnings {
  text-align: center;
  padding: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
