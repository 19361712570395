.story-title {
    @apply flex flex-col relative px-2;
}

.story-title-button {
    @apply ml-auto;
}

.story-title-prev-story {
    @apply mt-2 break-words whitespace-pre-wrap normal-case;
    /*margin-top: 0.5rem;*/
    /*word-wrap: break-word;*/
    /*white-space: pre-wrap;*/
    /*text-transform: none;*/
}

.story-title-prev-story * {
    @apply text-xl;
    /*font-size: 1.25rem;*/
}

.story-title-field {
    @apply text-xl block bg-gray-drk w-full;
    /*font-size: 1.25rem;*/
    /*display: block;*/
    /*background-color: theme('colors.gray.drk');*/
    /*width: 100%;*/
}

.story-title-field-div {
    @apply flex-grow pr-8;
    /*flex-grow: 1;*/
    /*padding-right: 2rem;*/
}

.story-title-container {
    @apply pt-2 bg-gray-light top-0 sticky z-10 flex flex-col;
    /*padding-top: 0.5rem;*/
    /*background-color: theme('colors.gray.light');*/
    /*top: 0;*/
    /*position: sticky;*/
    /*z-index: 10;*/

    /*display: flex;*/
    /*flex-direction: column;*/
}

.story-title-container hr {
    @apply mb-0;
    /*margin-bottom: 0;*/
}

.story-title-row {
    @apply flex flex-row items-stretch;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*align-items: stretch;*/
}

.story-title-label {
    @apply text-xl self-center text-center float-left w-1/4;
    /*font-size: 1.25rem;*/
    /*align-self: center;*/
    /*text-align: center;*/
    /*float: left;*/
    /*width: 25%;*/
}

.break-2 {
    @apply relative flex-grow flex justify-center items-center flex-col;
    /*position: relative;*/
    /*flex-grow: 1;*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /**/
    /*flex-direction: column;*/
}

.break-2-bg {
    @apply h-full w-full absolute z-0 bg-repeat bg-contain;
    background-image: url("../../../../src/images/fetti.png");
    animation: abc2 1.5s linear infinite;
    animation-timing-function: steps(1, start);
}

.break-2-img {
    @apply z-10 w-4/5 h-4/5
    /*z-index: 1;*/
    /*max-width: 80%;*/
    /*max-height: 80%;*/
}

.break-2-animation {
    animation: abc1 2.25s linear infinite;
    animation-timing-function: steps(1, start);
}

@keyframes abc1 {
    0%, 100% {
        transform: translateY(1rem);
    }
    50% {
        transform: translateY(-1rem);
    }

}

@keyframes abc2 {
    0%, 100% {
        background-position-y: 1rem;
    }
    50% {
        background-position-y: -1rem;
    }
}