.story-view-container {
    @apply w-full flex-grow flex flex-col;
}

.story-view-submission {
    @apply p-4 text-lg break-words whitespace-pre-wrap normal-case;
}

.prev-story {
    @apply relative m-1 flex-grow whitespace-pre-wrap normal-case text-lg max-w-full;
}