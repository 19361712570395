
.square-radio-row {
    @apply flex flex-row self-stretch justify-evenly w-[11rem];
}

.square-radio-row label {
    @apply text-3xl;

}

.square-radio-row input[type="radio"] {
    @apply opacity-0 h-0 w-0 m-0;

}


.square-radio-row input:focus-visible + label {
    outline: auto black thick !important;
}

.name-row input:focus-visible {
    outline: auto black thick !important;
}

.square-radio-row input[type="radio"] + label {
    @apply text-lg px-4 leading-relaxed;
    cursor: pointer;
    background-color: theme('colors.gray.light');
    border: 0.05rem solid #444;
    width: auto;
    text-align: center;


    display: flex;
    align-items: center;
    justify-content: center;
}

.square-radio-row input[type="radio"]:checked + label {
    background-color: theme('colors.gray.drk');
    outline: 0.2rem solid #444;
    z-index: 10;
}


.name-input {
    @apply pl-2 relative  bg-black text-white text-3xl leading-tight outline-none border-none;
    width: 11rem;
}

.name-row {
    @apply relative self-stretch flex justify-center items-center;
}

.sprite-name {
    @apply pl-2 relative text-left text-3xl leading-tight;
    width: 11rem;
    background-color: theme('colors.gray.drk');

    box-shadow: 0.1rem 0.1rem theme('colors.black');
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
