
.streamer-single {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.streamer-name {
    background-color: black;
    color: white;
}

.streamer-img {
    height: 64px;
    width: 64px;
    display: block;
}

.live-on-twitch {
    align-self: flex-end;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    cursor: pointer;
}