
.square-radio-buttons {
    flex: 1 1 0;
    display: flex;
}

.square-radio {
    align-self: stretch;
    flex-grow: 1;

    display: flex;
    flex-direction: row;
}

.square-radio input[type="radio"] {
    opacity: 0;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
}

.square-radio input[type="radio"] + label {
    @apply text-base;

    cursor: pointer;
    background-color: theme('colors.gray.light');
    padding: 0.25rem 0.25rem;
    border: 0.05rem solid #444;
    width: auto;
    text-align: center;


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    flex: 1 1 0;
}

.square-radio input[type="radio"]:disabled + label {
    @apply opacity-50;
}


.square-radio-subheader {
    @apply text-sm;

}