.book-container {
    @apply mx-0 md:mx-4 flex flex-col justify-center flex-grow relative;
    /*margin-left: 1rem;*/
    /*margin-right: 1rem;*/

    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
    /*flex-grow: 1;*/
    /*position: relative;*/
}


.page-body {
    @apply relative flex-grow basis-10 flex flex-col overflow-y-scroll overflow-x-hidden;
    /*position: relative;*/
    /*flex-grow: 1;*/
    /*flex-basis: 10rem;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*overflow-y: scroll;*/
    /*overflow-x: hidden;*/
}

.book-subheader {
    @apply text-sm sm:text-base bg-gray-med pl-2 self-stretch border-b-4 border-black;
    /*padding: 0 0.5rem;*/
    /*background-color: theme('colors.gray.med');*/
    align-self: stretch;

    /*border-bottom: black solid thick;*/
}

.wide-book {
    flex-grow: 1;
}