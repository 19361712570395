@media screen and (max-width: 640px) {


    .form-book {
        box-shadow: none !important;
        border-left: none !important;
        border-right: none !important;
        border-top: black solid 8px !important;
        border-bottom: black solid 8px !important;
        margin: 0 !important;
    }


    .adinplay-300x600 {
        display: none !important;
    }

    .adinplay-160x600 {
        display: none !important;
    }

}