.quick-start-ol {
    @apply m-4;
}

.quick-start-ol * {
    @apply text-base;
}

.quick-start-ol li {
    @apply text-base my-3;
}