.avatar4-square {
    flex-shrink: 0;
    height: 2.5em;
    width: 2.5em;
    background-color: white;
    position: absolute;
    opacity: 1.0;
    border: theme('colors.border.blue') solid;
}


.avatar4-container {
    font-size: 1em;
    max-width: 4em;
    height: 4em;

    position: relative;
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.avatar4 {
    flex-shrink: 0;
    font-size: 1em;
    position: relative;
    display: flex;

    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.avatar4-name-holder {
    position: absolute;
    bottom: 0;
    z-index: 30;
}

.avatar4-name {
    @apply bg-opacity-70 bg-black break-words z-30 text-white leading-none text-center;
    font-size: 0.6em;
    padding-left: 0.3em;
    padding-right: 0.3em;
    text-align: center;
}

.avatar4-img-holder {
    flex-shrink: 0;
    height: 3.5em;
    width: 3.5em;

    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar4-img {
    animation: bbbb 2s steps(1) infinite;

    max-height: 3.5em;
    max-width: 3.5em;
    width: auto;
    position: relative;
    display: block;
    margin: auto;
    z-index: 10;
}

.avatar4-img-no-bounce {

    max-height: 3.5em;
    max-width: 3.5em;
    width: auto;
    position: relative;
    display: block;
    margin: auto;
}

@keyframes bbbb2 {
    0%, 100% {
        transform: translate3d(0, 0.1rem, 0);
    }
    50% {
        transform: translate3d(0, -0.1rem, 0);
    }
}


@keyframes bbbb {
    0%, 100% {
        transform: translateY(0.1rem);
    }
    50% {
        transform: translateY(-0.1rem);
    }
}