.story-time-content-container {
    @apply text-base flex flex-col justify-center border border-black;
}

.story-time-content-container * {
    @apply text-lg;
    /*font-size: 1.25rem;*/
}

.story-time-footer-warning {
    animation: example steps(1, end) 2s infinite;
}

@keyframes example {
    0%, 100% {
        background-color: theme('colors.gray.med');
    }
    50% {
        background-color: theme('colors.border.magenta');
    }
}

.story-time-footer-normal {
}

.story-time-footer-timer {
    @apply self-stretch flex flex-col justify-center items-center text-xl;
}

.story-time-right-side {
    @apply flex-1 self-stretch flex flex-row justify-end space-x-2;
}

.story-time-vip-button {
    @apply self-stretch flex justify-center items-stretch text-sm;
}

.story-time-done-button {
    @apply self-stretch flex justify-center items-center min-w-0;
}

.story-time-vip-button-button {
    @apply self-stretch text-base flex-1 flex flex-col justify-center items-center text-center leading-none;
}

.story-time-footer-prompt {
    @apply self-stretch text-sm w-1/3 leading-none;
    /*flex-basis: 12rem;*/
}

.page-footer-story {
    @apply self-stretch mt-auto flex flex-row justify-start items-center h-16 space-x-2 text-base p-0.5;

    background-color: theme('colors.gray.med');
    border: solid theme('colors.gray.drker');
    border-right-color: theme('colors.gray.light');
    border-bottom-color: theme('colors.gray.light');

    border-top: #3a383b solid thick;
}

