.square-radio2 {
    @apply flex;
}

.square-radio2 input:focus-visible {
    outline: auto black thick !important;
}

.square-radio2 input[type="radio"] {
    @apply opacity-0 h-0 w-0 m-0;
}

.square-radio2 label {
    @apply flex-1 p-1 w-auto text-center flex items-center justify-center select-none;
    /*background-color: ;*/
    border: 0.05rem solid #444;
}

.square-radio2 input[type="radio"]:checked + label {
    @apply z-10 bg-gray-drk z-10;
    outline: 0.2rem solid #444;
}

.name-filler-left {
    @apply w-1/3 text-right text-2xl;
}

.name-row-data {
    @apply w-2/3 mr-2 ml-2;
}