.form-book {
    @apply relative shadow-aesthetic bg-gray-light flex-grow mx-4 flex flex-col justify-center border-black border-8;
}

.form-book-footer {
    @apply flex flex-row justify-between items-stretch h-14 p-0.5 text-2xl bg-gray-med sticky;

    border-style: solid;
    border-width: medium;

    border-color: theme('colors.gray.drker');
    border-right-color: theme('colors.gray.light');
    border-bottom-color: theme('colors.gray.light');
    border-top-color: theme('colors.gray.shadow');
    border-top-width: thick;
}

.form-book-submit {
    @apply self-stretch flex justify-center items-center p-2 text-xl leading-none max-w-[12rem];
}

.form-book-submit-flash {
    animation: submit-flash-frames steps(1, end) 2s infinite;
}

.submit-flash {
    animation: submit-flash-frames steps(1, end) 2s infinite;
}

@keyframes submit-flash-frames {
    0%, 100% {
        outline: none;
    }
    50% {
        outline: theme('colors.border.magenta') solid medium;
    }
}


.form-book-footer-countdown {

}

.form-book-footer-container {
    @apply self-stretch flex justify-center items-center;
}

.form-book-footer-container-error {
    @apply flex-1 flex justify-center items-center min-h-0;
}

.form-book-footer-error {
    @apply p-0.5 relative text-center max-h-full text-sm leading-tight overflow-hidden mx-0.5 border-dashed border-black;
}

.form-book-footer-back {
    @apply self-stretch w-24 text-xl leading-none;
}