.story-stats-row {
    align-self: stretch;

    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.story-stats-result-column {
    padding: 0.25rem;
    font-size: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    text-align: center;
    word-break: break-word;
    border-bottom: black solid medium;


}

.story-stats-label-column {
    padding: 0.25rem;
    font-size: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    text-align: center;
    word-break: break-word;
    border-right: black solid medium;
    border-bottom: black solid medium;
}

.centered-story-results2-stats {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    justify-content: center;
}

.unselected-characters-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.unselected-characters-row-item {
    font-size: 1.5rem;
    flex-grow: 1;

    display: flex;
    justify-content: center;
}

.story-stats2-container {
    display: flex;
    flex-direction: row;
}

.story-stats2-text {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.story-stats2-container-row {
    display: flex;
    flex-direction: column;
}

.story-stats2-characters {
    width: 75%;
}

.story-stats2-player {
    border-left: black solid medium;
    width: 25%;
}

.story-stats2-container2 {
    display: flex;
    flex-direction: column;
    outline: black solid thick;
}