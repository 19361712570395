@tailwind base;

@layer base {

    @font-face {
        font-family: 'FSEX302';
        src: local('FSEX302'), url(fonts/FSEX302.ttf) format('truetype');
        font-display: swap;
    }

    @font-face {
        font-family: 'Comic Sans';
        src: local('Comic Sans'), url(fonts/COMIC.TTF) format('truetype');
        font-display: swap;
    }

    :root {
        --bg-primary: #fec8fe;
        --bg-secondary: #ee63ee;

        --border-primary: #e21cff;
        --border-secondary: #1ef7ff;
        --border-tertiary: #e21cff;
    }

    ul {
        @apply list-disc list-inside
    }

    ol {
        @apply list-decimal list-inside
    }

    h1 {
        @apply text-3xl
    }

    h2 {
        @apply text-2xl my-2;
    }

    h3 {
        @apply text-xl;
    }

    h4 {
        @apply text-lg;
    }

    button, [type="button"], [type="submit"] {
        @apply bg-gray-drk shadow-basic;
    }


    .blood {
        --border-primary: #fc1c41;
        --border-secondary: #a11515;
        --border-tertiary: #4d0620;

        --bg-primary: #2F1119;
        --bg-secondary: #ba0000;
    }

    .goth {
        --border-primary: #78054e;
        --border-secondary: #3b888a;
        --border-tertiary: #35163d;

        --bg-primary: #624778;
        --bg-secondary: #590654;
    }

    .blue {
        --border-primary: #5c287d;
        --border-secondary: #88ebdc;
        --border-tertiary: #9d88eb;

        --bg-primary: #cce5ed;
        --bg-secondary: #dba1ff;
    }

    .neon {
        --border-primary: #7b28f7;
        --border-secondary: #f728e2;
        --border-tertiary: #ba24ff;

        --bg-primary: #daff91;
        --bg-secondary: #20fa45;
    }

    .night {
        /*--border-primary: '';*/
        /*--border-secondary: '';*/
        /*--border-tertiary: '';*/

        --bg-primary: #bbb4bc;
        --bg-secondary: #7e7b7f;
    }

    .nineties {
        --border-primary: #d408a8;
        --border-secondary: #80ffe8;
        --border-tertiary: #d408a8;

        --bg-primary: #ff8cff;
        --bg-secondary: #81f7ed;
    }

    .sparkle {
        --border-primary: #5c5c05;
        --border-secondary: #fffee8;
        --border-tertiary: #edbe00;

        --bg-primary: #ffeda6;
        --bg-secondary: #fffdf5;
    }

    .worm {
        background-size: 5rem 5rem !important;
        background-image: url('images/smallest.gif') !important;
    }

    .petal {
        --border-primary: #ffee7d;
        --border-secondary: #822f9c;
        --border-tertiary: #ffeeb5;

        --bg-primary: #fec85e;
        --bg-secondary: #d64d5b;
    }

    .hacker {
        --border-primary: #155720;
        --border-secondary: #85ff91;
        --border-tertiary: #021c05;

        --bg-primary: #839189;
        --bg-secondary: #abfc14;
    }

    .winter {
        --border-primary: #5c1680;
        --border-secondary: #27b0cc;
        --border-tertiary: #5c1680;

        --bg-primary: #a2a8a8;
        --bg-secondary: #d4fffa;
    }


}

@tailwind components;
@tailwind utilities;


@layer components {
    .franticfanficbg {
        background-size: 1.5rem 1.5rem;
        background-color: var(--bg-primary);
        background-image: linear-gradient(to right, var(--bg-secondary) 1.2px, transparent 1px), linear-gradient(to bottom, var(--bg-secondary) 1.2px, transparent 1px);
    }
}


body {
    @apply uppercase
}


button {
    @apply uppercase bg-gray-drk px-0.5 flex flex-col justify-center items-center disabled:opacity-50 leading-tight shadow-basic;
    /*box-shadow: 0.1rem 0.1rem theme('colors.black');*/
    border-top-color: #767676;
    border-left-color: #767676;
    border-right-color: #212121;
    border-bottom-color: #212121;
    border-width: 0.1rem;

    white-space: break-spaces;
    flex-shrink: 0;
}

input[type=submit] {
    @apply uppercase bg-gray-drk shadow-basic px-0.5 flex flex-col justify-center items-center disabled:opacity-50;

    border-top-color: #767676;
    border-left-color: #767676;
    border-right-color: #212121;
    border-bottom-color: #212121;
    border-width: 0.1rem;
}

input[type=button] {
    @apply uppercase bg-gray-drk shadow-basic px-0.5 flex flex-col justify-center items-center disabled:opacity-50;

    border-top-color: #767676;
    border-left-color: #767676;
    border-right-color: #212121;
    border-bottom-color: #212121;
    border-width: 0.1rem;
}

input[type=text], input[type=password] {
    @apply leading-none !important;
    box-shadow: 0.1rem 0.1rem theme('colors.black');

    border-top-color: #767676;
    border-left-color: #767676;
    border-right-color: #212121;
    border-bottom-color: #212121;
    border-width: 0.1rem;
}

a {
    @apply underline text-blue-600 hover:text-blue-800 visited:text-purple-600;
}


a > button {
    @apply no-underline !important;
}