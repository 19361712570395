
.page-main-button {
    @apply w-full text-4xl leading-snug px-2 bg-gray-drk;
}

.terms-link {
    @apply cursor-pointer underline block text-center italic;
}

.page-main-buttons {
    @apply relative self-center flex flex-col items-stretch space-y-2.5 flex-shrink-0;
}

.page-main-buttons a {
    @apply flex-shrink-0;
}

.page-main-blog {
    @apply p-2;
}

.page-main-blog h2 {
    @apply font-extrabold;
}

.page-main-blog h3 {
    @apply font-bold;

}

.page-main-blog h4 {
    @apply font-semibold;

}

.page-main-blog * p {
    @apply normal-case;
    /*font-family: "Comic Sans", serif;*/

}

.page-main-blog * li {
    @apply ml-2 normal-case;
}