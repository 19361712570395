.create-game-button-container {
    @apply self-stretch w-1/3 h-full flex justify-center items-center;
}

.square-radio-group {
    @apply self-stretch py-0 flex flex-row justify-between items-center;
}

.square-radio input[type="radio"] {
    opacity: 0;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
}

.square-radio input[type="radio"] + label {
    @apply text-base;

    cursor: pointer;
    flex-grow: 1;
    background-color: theme('colors.gray.light');
    padding: 0.25rem 0.25rem;
    border: 0.05rem solid #444;
    width: auto;
    text-align: center;


    display: flex;
    align-items: center;
    justify-content: center;
}

.square-radio input[type="radio"]:checked + label {
    background-color: theme('colors.gray.drk');
    outline: 0.2rem solid #444;
    z-index: 10;
}

.square-radio input[type="radio"]:focus-visible + label {
    outline: auto thick black;
}

.square-radio {
    align-self: stretch;
    flex-grow: 1;

    display: flex;
    flex-direction: row;
}

.advanced-button {
    background: none !important;
    box-shadow: none !important;;
    border: none !important;
}


.create-game {
    @apply flex-1 p-4 flex flex-col justify-center items-center space-y-2;
}

.button {
    background-color: #9d969e;
    align-self: center;
}

.max-players-select {
    @apply text-2xl;

    text-align-last: center;
    text-align: center;
    height: 100%;
    background-color: #ddd4de;
    font-weight: bold;
}

.max-players-option {
    @apply text-2xl;

    text-align-last: center;
    text-align: center;
    background-color: #ddd4de;
    font-weight: bold;
}