



.censor-button-input {
    opacity: 0;
    height: 0;
    width: 0;
    margin: 0;
}

.censor-button-input:focus-visible + label {
    outline: auto black medium !important;
}

.censor-button-input:checked + label {
    background-color: black;
    color: theme('colors.gray.med');
}