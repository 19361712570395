.picker-container {
    @apply h-full w-full flex flex-row;
}

.picker-container-arrow {
    @apply self-center text-lg leading-none px-1.5 leading-snug;
}

.picker-container-label {
    @apply mx-2 self-center text-center flex-1 flex flex-col bg-gray-drk outline outline-2 outline-[#444] text-sm break-words;

}

.picker-container-label div {
    @apply w-full break-words;
}

.picker-container-label input {
    @apply w-full break-words text-center;
}