.story-button-wrapper {
  @apply flex-grow min-w-0 cursor-pointer;

}

.story-button-container {

}

.story-button {
  @apply w-full p-0 text-lg;
}

.story-button-content {
  @apply break-words p-0.5 w-full;

}