
.cool-footer {
    @apply ml-auto flex flex-row justify-end items-stretch;
}

.story-results2-row2-result:nth-child(2) .story-results2-story-placing {
    background-color: theme('colors.border.blue');
}

.story-results2-row2-result:nth-child(n+3) .story-results2-story-placing {
    background-color: theme('colors.light.ltBlue');
}

.story-results2-row2-result:nth-child(2) .story-results2-player-placing {
    background-color: theme('colors.border.magenta');
}

.story-results2-row2-result:nth-child(n+3) .story-results2-player-placing {
    background-color: theme('colors.light.ltPink');
}


.story-results2-row2-result {
    align-self: stretch;

    display: flex;
    flex-direction: row;
    align-items: stretch;
    border-bottom: black solid thick;
}

.story-results2-fanfic-card2 {
    border-right: black solid thick;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
}

.story-results2-player-card2 {
    border-left: black solid thick;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 1rem;
    width: 30%;
    min-height: 15rem;
}

.story-results2-fanfic-column {
    flex-grow: 3;
}

.story-results2-player-column {
    flex-grow: 1;
}


.story-results2-row2 {
    @apply flex flex-row;
}

.story-results2-container2 {
    @apply flex flex-col;
}

.story-download-container {
    @apply flex flex-row justify-around;
}

.story-results2-button-border {
    border-top: black solid thick;
    border-bottom: black solid thick;
}

.story-results2-container {
    @apply flex flex-row p-4;
}

.story-results2-stories {
    @apply flex-grow flex flex-col mr-1.5;
}

.story-results2-story-placing {
    box-shadow: 2px 2px black;
    padding: 0.25rem;

    text-align: center;


}

.story-results2-story {
    margin-bottom: 1rem;
}

.story-results2-story:nth-child(2) .story-results2-story-placing {
    background-color: theme('colors.border.blue');
}

.story-results2-story:nth-child(n+3) .story-results2-story-placing {
    background-color: theme('colors.light.ltBlue');
}


.story-results2-title-container {
    flex-grow: 1;
    height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.story-results2-title-container button {
    flex-grow: 1;
    word-break: break-word;
}

.story-results2-title {
    background-color: theme('colors.gray.drk');
    box-shadow: 2px 2px black;

    text-align: center;
    padding: 0.25rem;
}

.story-results2-container * {
    font-size: 1rem;
}

.story-results2-header {
    color: white;
    background-color: black;
    text-align: center;
    padding: 0.25rem;
    font-size: 1rem;
}

.story-results2-players {
    display: flex;

    flex-direction: column;

    margin-left: 0.4rem;
}

.story-results2-player-placing {
    margin-bottom: auto;
    text-align: center;
    box-shadow: 2px 2px black;
    padding: 0.25rem;
}

.story-results2-player:nth-child(2) .story-results2-player-placing {
    background-color: theme('colors.border.magenta');
}

.story-results2-player:nth-child(n+3) .story-results2-player-placing {
    background-color: theme('colors.light.ltPink');
}

.story-results2-player {
    margin-bottom: 1rem;
}

.story-results2-avatar {
    font-size: 2rem;
    flex-grow: 1;
    height: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.results-big-reactions {
    font-size: 1.25rem;
}


.results-big-reactions-container i {
    font-size: 1.1rem;
    font-weight: bold;
}

.character-submit-header-shit {
    height: 10rem;

    font-size: 1.5rem;
    background-color: theme('colors.gray.drk');
    border-bottom: black solid thick;
}

.character-submit-header-title-shit {
    font-size: 1.5rem;
    background-color: theme('colors.gray.drk');
    border-bottom: black solid thick;

    display: block;
    text-align: center;
    word-break: break-word;
}