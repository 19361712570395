.blog-container * p {
    text-transform: none;
    font-family: "Comic Sans", serif;

}

.blog-container {
    padding: 1rem;
    font-family: "Comic Sans", serif;
}

.blog-container-footer-buttons {
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-end;
}

.blog-container-footer-button {
    margin-left: 1rem;
    margin-right: 1rem;
}