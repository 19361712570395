.vote-row-buttons {
    @apply text-base flex justify-center items-center text-center uppercase pb-1;
}

.vote-row-buttons-label {
    @apply italic text-base;
}

.bbb-label {
    @apply relative cursor-pointer border border-transparent flex text-center justify-center items-center select-none z-10;
}

.bbb-label-nofancy {
    @apply opacity-0 -z-10;
}

.bbb-label-fancy {
    @apply opacity-0 -z-10;
    animation: cssAnimation 0.5s linear;
}


@keyframes cssAnimation {
    from {
        transform: scale(1) translate(0);
        opacity: 1;

    }
    to {
        transform: scale(2) translate(0, -1rem);
        opacity: 0;
    }
}

.bbb-label-red {
    @apply text-red-600;
}

.vote-row-buttons-input {
    @apply opacity-0 h-0 w-0 m-0;
}

.vote-row-buttons-input:focus-visible + label {
    outline: auto black medium !important;
}

.vote-row-buttons label {
    @apply self-stretch;
}

.vote-row-buttons input[type="checkbox"] + label {
    @apply flex justify-center items-center p-1;
    filter: grayscale(100%) contrast(100%) brightness(85%);
}


.vote-row-buttons input[type="checkbox"]:checked + label {
    @apply flex justify-center items-center p-1;

    filter: contrast(100%) brightness(100%) saturate(100%) !important;
    /*text-underline-offset: 5px;*/
}

.vote-row-buttons input[type="checkbox"]:hover + label {
    filter: grayscale(50%) contrast(100%) brightness(85%);
    /*text-underline-offset: 5px;*/
}