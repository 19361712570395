@media screen and (min-width: 640px) and (max-width: 768px) {


    .form-book {
        /*border: black solid 10px !important;*/
        margin: 0 !important;
    }


    .adinplay-300x600 {
        display: none !important;
    }

    .adinplay-160x600 {
        display: none !important;
    }


}