.lobby-container {
    @apply flex-grow flex bg-contain bg-no-repeat flex-col w-full;
    background-image: url("../../../../src/images/room_asset2.png");
}

.magic-book-button {
    height: 35%;
    position: absolute;
    bottom: 0;
    border: 0;
    padding: 0;
    background: transparent;
    box-shadow: none;
    transform: none;

    display: flex;
    flex-direction: column-reverse;
}

.lobby-players2 {
    @apply pt-8 flex-grow relative flex flex-row flex-wrap justify-center;
}

.lobby-players2 > {
    @apply min-h-0;
}

.square {
    @apply relative w-full flex;
}

.square:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}