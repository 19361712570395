
.canvas-view-container {
    @apply h-full w-full -z-10 absolute overflow-y-scroll overflow-x-hidden;
}


.canvas-view-book {
    @apply flex-grow relative flex flex-col items-stretch pb-8 bg-gray-light;
}


.canvas-page-body {
    @apply relative flex-grow flex flex-col;
}

.canvas-story-view-container {
    @apply flex-grow flex flex-col;
}

.canvas-story-view-submission {
    @apply px-4 pt-2 text-base break-words whitespace-pre-wrap normal-case;
}