.app-top-roomLabel {
    @apply flex flex-row justify-center items-center px-1 leading-none text-xl relative self-end bg-night-primary text-center z-10 border-b-0 min-h-[2rem] sm:shadow-aestheticTop cursor-pointer  ;
    flex-basis: 6rem;
    border: black medium solid;
    border-bottom-width: 0;
}

.app-top-roomLabel-no-border {
    @apply flex flex-row p-0.5 items-center leading-none text-xl relative bg-night-primary  text-center z-10 shadow-basicSmall cursor-pointer border-black border border-l-0;

}

.app-top-roomLabel span {
    @apply text-xl text-center flex justify-center items-center leading-none;
}