.story-create {
    @apply flex-grow flex flex-col justify-around p-2;
}

.story-characters {
    @apply flex flex-col space-y-3;
}

.story-characters-row {
    @apply flex flex-row-reverse items-stretch justify-between mx-4 mt-2;
}

.story-characters-row label {
    @apply flex-grow;
}

.story-characters-row input[type='checkbox'] {
    @apply cursor-pointer self-center ml-1;
    zoom: 2;
}

.story-characters-row input[type='checkbox']:checked + label {
    @apply outline outline-black outline-2 bg-night-primary;
}

.story-characters-row input[type='checkbox'] + label {
    @apply text-2xl cursor-pointer outline outline-night-primary outline-1 pl-1 flex justify-center items-center leading-relaxed;
}


.mature-select-container {
    @apply flex-grow flex flex-row items-center justify-evenly;
}

.mature-select-title {
    @apply p-1 text-2xl mr-2;
}



.select-row {
    @apply self-stretch flex flex-row;
}

.square-radio {
    @apply flex;
}

.mature-select {
    @apply flex flex-row items-center;
}

.mature-select input[type="radio"] {
    @apply opacity-0 h-0 w-0 m-0;
}


.mature-select input[type="radio"] + label {
    @apply text-lg cursor-pointer bg-gray-light p-1 border border-solid border-[#444] ml-1 leading-none;
}

.mature-select input[type="radio"]:checked + label {
    @apply bg-gray-drk outline outline-4 outline-[#444];
}

.mature-select input[type="radio"]:disabled + label {
    @apply opacity-50;
}

.mature-select input[type="radio"]:focus-visible + label {
    outline: auto black medium !important;
}


.select-row input[type="submit"] {
    @apply bg-gray-drk shadow-basic ml-auto;
}

.mature-square-radio-group {
    @apply flex flex-row items-stretch cursor-pointer;
}

.mature-square-radio-group > label {
    @apply flex justify-center items-center mx-1 leading-none;
}