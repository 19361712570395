.waiting-container {
    flex-grow: 1;
    font-size: 1em;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.waiting-avatars {
    align-self: stretch;
    font-size: 2rem;
    padding: 0.5rem;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.waiting-avatar-clicked {
    background-color: theme('colors.border.magenta');
}