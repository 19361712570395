.countdown-container {
    @apply flex-grow text-center self-center text-xl flex justify-start;
    /*flex-grow: 1;*/
    /*text-align: center;*/
    /*align-self: center;*/
    /*font-size: 1.5rem;*/
    /*display: flex;*/
    /*justify-content: left;*/
}

.countdown-warning {
    animation: countdown-flash steps(1, end) 2s infinite;
}

@keyframes countdown-flash {
    0%, 100% {
        background-color: theme('colors.gray.med');
    }
    50% {
        background-color: theme('colors.border.magenta');
    }
}