.character-submit-form {
    @apply flex-1 flex flex-col flex-nowrap justify-evenly items-center;
}

/*.character-submit-form div {*/
/*    @apply flex flex-row text-3xl text-center;*/
/*}*/

.character-submit-form label {
    @apply border-black text-center text-3xl bg-gray-drk border border-black;
}

.character-submit-form input[type="text"] {
    @apply bg-gray-drk flex-grow text-3xl;

}

.character-submit-form input[type="text"]:disabled {
    @apply text-gray-300;
}

