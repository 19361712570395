.story-vote-container {
    @apply flex-grow flex flex-col items-center p-4;
}

.story-vote-card-container {
    @apply flex-grow;
}

.story-vote-label {
    @apply flex-grow mr-4 ;
}

.story-vote-award-title {
    background-color: black;
    color: white;
    padding: 0.25rem 1rem;
    box-shadow: 2px 2px theme('colors.gray.shadow');
    font-size: 1.5rem;
    text-align: center;
}

.story-vote-award-subtitle {
    @apply mt-1 text-lg text-center;
}

.story-vote-stories {
    @apply mt-4 self-stretch flex-grow flex flex-col items-stretch;
}

.story-vote-row {
    @apply self-stretch mb-4 flex flex-row items-center;
}

.story-vote-checkbox {
    @apply cursor-pointer;
    zoom: 3;
}