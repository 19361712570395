.story-list-card-container {
    @apply flex flex-row;
    /*display: flex;*/
    /*flex-direction: row;*/

}

.story-list-container {
    @apply flex-grow flex flex-col space-y-2 m-4;
    /*margin: 1em;*/
    /*flex-grow: 1;*/
    /**/
    /*display: flex;*/
    /*flex-direction: column;*/
}

.story-list-card-label {
    @apply flex flex-col justify-center flex-grow mr-8;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
    /*flex-grow: 1;*/
    /*margin-right: 2em;*/
}

.story-list-card-label button {
    @apply flex-grow;
    /*flex-grow: 1;*/

}

.story-list-card-avatar {
    @apply text-2xl ml-4 self-center;
    /*font-size: 1.5rem;*/
    /*margin-left: 1rem;*/
    /*align-self: center;*/
}

.story-list-download-all {
    @apply text-base sm:text-xl sm:mx-4;
}
